<template>
  <div>
    <v-toolbar class="mt-8 mb-4" elevation="0" style="background: none">
      <v-toolbar-title class="font-weight-bold">
        Registro de Manejo / {{ managementIndexesByField.farm }} / {{ managementIndexesByField.field }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Informações gerais -->
    <v-card class="mb-12 rounded-xl">
      <v-card-title class="yellow-border">
        <v-container class="custom-container">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
              <span class="font-weight-bold">Informações Gerais</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <p>
                <span><b>Proprietário</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementIndexesByField.producer }}
                </span>
              </p>
            </v-col>

            <v-col cols="3">
              <p>
                <span><b>Fazenda</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementIndexesByField.farm }}
                </span>
              </p>
            </v-col>

            <v-col cols="3">
              <p>
                <span><b>Safra</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementIndexesByField.harvest }}
                </span>
              </p>
            </v-col>

            <v-col cols="3">
              <p>
                <span><b>Talhão</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ managementIndexesByField.field }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container mt-6">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Índices de Fertilidade</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6"
              v-for="managementIndex in managementIndexesByField.indexes" 
              :key="managementIndex.id">
              <span class="font-weight-light">
                <p :class="verifyGrade(managementIndex.grade)">
                  {{ managementIndex.name }}: {{ managementIndex.grade || 'N/A' }}
                </p>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import ManagementRecords from "@/domain/records/management-records/management-records";

export default {
  name: "ManagementRecordFieldDetails",

  data() {
    return {
      fieldId: "",
      harvest: "",
      payload: {},
      managementIndexesByField: {},
      managementRecordService: null,
    }
  },

  methods: {
    loadIndexesByField(fieldId, harvest) {
      this.managementIndexesByField = {};

      this.managementRecordService
      .getManagementRecordByField(fieldId, harvest)
      .then(result => {
        this.managementIndexesByField = result.data;
      });
    },

    closeView() {
      this.$router.push({
        'name': 'management-record-details',
        'params': {
          'farmId': this.managementIndexesByField.farm_id, 'harvests': this.harvest
        }
      });
    },

    verifyGrade(grade) {
      return !grade && 'no-grade';
    }
  },

  mounted() {
    this.fieldId = this.$route.params.fieldId;
    this.harvest = this.$route.params.harvest;

    this.loadIndexesByField(this.fieldId, this.harvest);
  },

  beforeMount() {
    this.payload                = ManagementRecords.newData();
    this.managementRecordService = ManagementRecords;
  }
};
</script>

<style scoped>
  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }

  .no-grade {
    color: red;
    font-weight: bold;
  }
</style>